import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#0672cb',
    },
    secondary: {
      main: '#000',
    },
  },
  typography: {
    fontSize: '2rem',
    fontWeight: 300,
    fontFamily: '"SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Roboto", "Helvetica", "Arial", sans-serif',
  },
})
