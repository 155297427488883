/**
 *
 * @description Footer.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import { makeStyles, Hidden, Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  footer: {
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    color: '#636363',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  phoneNumber: {
    textDecoration: 'none',
    color: '#636363',
  },
  enquiryEmail: {
    textDecoration: 'none',
    color: '#636363',
  },
  siteMap: {
    textDecoration: 'none',
    color: '#636363',
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Hidden smUp>
        <div>
          <img alt='iso' src={require('../images/iso-logo.png')} style={{ padding: '5px 15px', height: 150 }} />
        </div>
      </Hidden>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <a href='https://wa.me/60122466356?text=Hello%20Almag%20teams,%0A%0A' className={classes.phoneNumber}>
            <img src={require('../images/whatsapp-icon.jpeg')} alt='mail' width={50} height={50} />
            {/* + 603-8724 1111 */}
          </a>
        </Grid>
        <Grid item xs={4}>
          <a className={classes.enquiryEmail} href='mailto:enquiry@almagsb.com'>
            <img src={require('../images/mail-icon.png')} alt='mail' width={50} height={50} />
            {/* enquiry@almagsb.com */}
          </a>
        </Grid>
        <Grid item xs={4}>
          <a
            className={classes.siteMap}
            href='https://www.google.com/maps/dir//1,+Jalan+P2%2F18+Bandar+Teknologi+Kajang+43500+Semenyih+Selangor/@2.9664898,101.8277609,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x31cdcc3b0b0a02fd:0xbb6d235661b181f9'
          >
            <img src={require('../images/location-icon.png')} alt='mail' width={50} height={50} />
            {/* Site Map */}
          </a>
        </Grid>
        <Grid item xs={12}>
          © 1986 - {new Date().getFullYear()} Almag Sdn. Bhd. (144982-V)
        </Grid>
      </Grid>
    </footer>
  )
}
