/**
 *
 * @description NavMenu.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Hidden, Dialog } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'black',
    '&:visited': {
      color: 'black',
    },
    '&:hover div': {
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
    },
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    '& div': {
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
    },
  },
  linkContainer: {
    display: 'inline',
    paddingBottom: theme.spacing(1),
  },
  isoLogo: {
    width: 160,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    transition: 'width 0.5s ease',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hideISOLogo: {
    width: 0,
    transition: 'width 0.5s ease',
  },
}))

export default function NavMenu({ onClickMobileMenu }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Hidden xsDown>
        <img
          alt='iso'
          src={require('../images/iso-logo.png')}
          style={{ marginBottom: 0 }}
          className={`${classes.isoLogo}`}
          onClick={() => setOpen(true)}
          onKeyUp={() => {}}
        />
        <Link className={classes.link} activeClassName={classes.active} to='/'>
          <div className={classes.linkContainer}>Home</div>
        </Link>
        <Link className={classes.link} activeClassName={classes.active} to='/about-us'>
          <div className={classes.linkContainer}>About Us</div>
        </Link>
        <Link className={classes.link} activeClassName={classes.active} to='/products'>
          <div className={classes.linkContainer}>Products</div>
        </Link>
        <Link className={classes.link} activeClassName={classes.active} to='/enquiry'>
          <div className={classes.linkContainer}>Enquiry</div>
        </Link>
      </Hidden>
      <Hidden smUp>
        <MenuIcon onClick={onClickMobileMenu} />
      </Hidden>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <img alt='iso' src={require('../images/iso-logo.png')} style={{ marginBottom: 0 }} />
      </Dialog>
    </div>
  )
}
